<template>
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.8789 189.445C53.7891 189.445 48.8789 187.422 45.2617 183.801C37.7617 176.301 37.7617 164.09 45.2617 156.582L77.3047 124.539C78.3711 123.473 80.1055 123.473 81.1719 124.539C82.2383 125.605 82.2383 127.34 81.1719 128.406L49.1289 160.449C43.7578 165.824 43.7578 174.562 49.1289 179.934C51.8945 182.703 55.7187 184.164 59.6289 183.953C63.5312 183.738 67.1758 181.867 69.6211 178.816L100.707 140.066L85.6016 119.527C84.707 118.312 84.9688 116.598 86.1836 115.703C87.4023 114.809 89.1133 115.07 90.0078 116.285L106.359 138.519C107.094 139.519 107.066 140.883 106.289 141.852L73.8867 182.238C70.4688 186.5 65.3828 189.117 59.9258 189.414C59.5742 189.434 59.2266 189.445 58.8789 189.445Z"
      fill="#3AB54A"
    />
    <path
      d="M121.853 120.813C121.153 120.813 120.454 120.547 119.919 120.012L102.81 102.902C101.743 101.836 101.743 100.102 102.81 99.0353L124.915 76.9259L119.22 55.6642C118.966 54.7189 119.235 53.715 119.927 53.0236L145.579 27.3712C146.271 26.6798 147.278 26.4103 148.22 26.6642L166.532 31.5743C167.478 31.8283 168.212 32.5626 168.466 33.5079C168.72 34.4532 168.45 35.4572 167.759 36.1486L148.192 55.715L151.853 69.3908L165.536 73.0548L185.103 53.4884C185.794 52.797 186.802 52.5275 187.743 52.7814C188.688 53.0353 189.423 53.7697 189.677 54.715L194.587 73.0275C194.841 73.9728 194.571 74.9767 193.88 75.6681L168.228 101.32C167.536 102.012 166.528 102.281 165.587 102.027L143.075 95.9962L131.333 110.629C130.388 111.809 128.669 111.996 127.489 111.051C126.31 110.106 126.122 108.383 127.067 107.207L139.927 91.1798C140.606 90.3322 141.72 89.9689 142.767 90.2501L165.478 96.3361L188.896 72.9181L185.622 60.7033L168.29 78.0353C167.599 78.7267 166.595 78.9962 165.649 78.7423L148.919 74.2618C147.974 74.0079 147.239 73.2697 146.985 72.3283L142.509 55.6017C142.255 54.6564 142.524 53.6525 143.216 52.9611L160.548 35.629L148.333 32.3556L124.915 55.7736L130.61 77.0353C130.864 77.9806 130.595 78.9845 129.903 79.6759L108.614 100.969L123.79 116.145C124.856 117.211 124.856 118.945 123.79 120.012C123.255 120.543 122.552 120.813 121.853 120.813Z"
      fill="#3AB54A"
    />
    <path
      d="M147.438 189.441C140.786 189.441 134.517 186.211 130.665 180.797L73.958 103.699C73.0635 102.484 73.3252 100.77 74.54 99.875C75.7549 98.9805 77.4697 99.2422 78.3643 100.457L135.087 177.57C135.095 177.582 135.106 177.594 135.114 177.609C137.985 181.652 142.478 183.973 147.442 183.973C151.466 183.973 155.255 182.402 158.106 179.551C160.958 176.699 162.528 172.906 162.528 168.871C162.528 164.836 160.958 161.047 158.106 158.191L82.6103 82.7031L70.9775 94.3359C69.9111 95.4023 68.1768 95.4023 67.1104 94.3359C66.0439 93.2695 66.0439 91.5352 67.1104 90.4688L80.6807 76.8984C81.7471 75.832 83.4814 75.832 84.5479 76.8984L161.974 154.324C165.86 158.211 167.997 163.375 167.997 168.871C167.997 174.363 165.856 179.531 161.974 183.418C158.083 187.305 152.923 189.441 147.438 189.441Z"
      fill="#006837"
    />
    <path
      d="M64.8361 99.3437C64.1369 99.3437 63.4377 99.078 62.9025 98.5429L53.672 89.3163C53.1525 88.7968 52.8634 88.0898 52.8712 87.3515C52.8791 86.6171 53.1837 85.914 53.715 85.4062L74.797 65.2108C75.8712 64.1796 77.5705 64.1991 78.6213 65.2499L87.4064 74.0351C88.4728 75.1015 88.4728 76.8358 87.4064 77.9023L66.7697 98.539C66.2384 99.0741 65.5353 99.3437 64.8361 99.3437ZM59.5158 87.4257L64.8361 92.7421L81.6095 75.9687L76.6525 71.0116L59.5158 87.4257Z"
      fill="#3AB54A"
    />
    <path
      d="M72.0908 55.8516C71.3916 55.8516 70.6924 55.5859 70.1572 55.0508L54.0283 38.9219C52.9619 37.8555 52.9619 36.1211 54.0283 35.0547L58.3564 30.7266L56.3408 21.4297C56.1455 20.5195 56.4229 19.5742 57.0791 18.918L64.6416 11.3555C65.708 10.2891 67.4424 10.2891 68.5088 11.3555L97.7197 40.5664C98.7861 41.6328 98.7861 43.3672 97.7197 44.4336L90.1572 51.9961C89.501 52.6523 88.5518 52.9297 87.6455 52.7344L78.3486 50.7188L74.0205 55.0469C73.4893 55.582 72.79 55.8516 72.0908 55.8516ZM59.8291 36.9883L72.0908 49.25L75.54 45.8008C76.1963 45.1445 77.1416 44.8633 78.0518 45.0625L87.3486 47.0781L91.9229 42.5039L66.5752 17.1562L62.001 21.7305L64.0166 31.0273C64.2119 31.9375 63.9346 32.8828 63.2783 33.5391L59.8291 36.9883Z"
      fill="#006837"
    />
    <path
      d="M12.4695 95.8202C12.368 95.8202 12.2703 95.8163 12.1688 95.8046C11.3445 95.7147 10.6063 95.2538 10.161 94.5507C6.41877 88.6483 4.76252 81.5507 5.49299 74.5624C6.23517 67.4686 9.42658 60.7811 14.4813 55.7304L29.6063 40.9765C30.6883 39.9218 32.4188 39.9452 33.4735 41.0233C34.5281 42.1054 34.5047 43.8358 33.4266 44.8905L18.325 59.621C10.7117 67.2382 8.74298 78.9413 13.1258 88.5663L30.2235 71.4686C31.2899 70.4022 33.0242 70.4022 34.0906 71.4686L50.8172 88.1952L77.6219 62.5155L47.1336 32.0272L45.4539 33.6366C44.3641 34.6796 42.6336 34.6444 41.5867 33.5546C40.5438 32.4647 40.5789 30.7343 41.6688 29.6874L45.2821 26.2265C46.3563 25.1991 48.0555 25.2147 49.1063 26.2655L83.4657 60.6249C83.9852 61.1444 84.2742 61.8515 84.2664 62.5858C84.2586 63.3202 83.9539 64.0233 83.4227 64.5311L52.6688 93.996C51.5946 95.0233 49.8953 95.0077 48.8446 93.9569L32.1531 77.2655L14.4031 95.0155C13.8875 95.535 13.1922 95.8202 12.4695 95.8202Z"
      fill="#006837"
    />
    <path
      d="M37.3984 40.0742C37.2227 40.0742 37.043 40.0546 36.8672 40.0195C36.6914 39.9843 36.5195 39.9335 36.3555 39.8632C36.1875 39.7968 36.0313 39.7109 35.8828 39.6132C35.7344 39.5117 35.5938 39.3984 35.4648 39.2734C35.3398 39.1484 35.2266 39.0078 35.125 38.8593C35.0273 38.7109 34.9453 38.5507 34.875 38.3867C34.8086 38.2187 34.7539 38.0468 34.7187 37.8749C34.6836 37.6992 34.6641 37.5195 34.6641 37.3398C34.6641 36.621 34.957 35.914 35.4648 35.4062C35.5938 35.2812 35.7344 35.164 35.8828 35.0663C36.0313 34.9687 36.1875 34.8827 36.3555 34.8163C36.5195 34.746 36.6914 34.6953 36.8672 34.6601C37.7539 34.4804 38.6992 34.7734 39.332 35.4062C39.8438 35.914 40.1328 36.621 40.1328 37.3398C40.1328 38.0585 39.8438 38.7656 39.332 39.2734C38.8242 39.7812 38.1211 40.0742 37.3984 40.0742Z"
      fill="#006837"
    />
  </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
